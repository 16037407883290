import React, { useState } from "react";
import Header from "./microComponents/Header.js";
import LayeredWaves from "./microComponents/LayeredWaves.js";
import { ProgressBar } from "react-bootstrap";
import { storage, db } from "../services/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { addDoc, serverTimestamp, collection } from "firebase/firestore";
// import { Container, Row, Col }  from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";

// function makeid(length) {
//   var result = "";
//   var characters =
//     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   var charactersLength = characters.length;
//   for (var i = 0; i < length; i++) {
//     result += characters.charAt(Math.floor(Math.random() * charactersLength));
//   }
//   return result;
// }

const StudentSpeakerApply = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    why: "",
    idea: "",
    email: "",
    videoURL: "",
    university: "",
    course: "",
    title: "",
    yearOfStudy: undefined,
    applicationTime: serverTimestamp(),
  });

  const [progress, setProgress] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [file, setFile] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(formData);
    const storageRef = ref(storage, `speaker-videos/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    try {
      setIsUploading(true)
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          alert(error);
          setIsUploading(false);
        },
        () => {
          setIsUploading(false);
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFormData({ ...formData, videoURL: downloadURL });
            let formCopy = formData;
            formCopy.videoURL = downloadURL;
            addDoc(
              collection(db, "conference", "2023", "applicants"),
              formData
            );
          });
        }
      );
    } catch (err) {}
    setMessage(
      "Thank you for your submission, we will update you about our decision soon!"
    );
  };

  const handleInputData = (input) => (e) => {
    const { value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  return (
    <div>
      <div className="main-container">
        <Header />
        <div className="student-speaker">
          <h1>Apply to be a Student Speaker</h1>
          <br />
          <h3>
            Want a chance to speak alongside internationally renowned guests?
          </h3>
          <p>
            TEDxOxfords student speaker competition <b>is open now!</b> Students
            from Oxford and Oxford Brookes can have the chance to feature in our
            upcoming event on Jan 29th, 2022.
          </p>
          <h4>What is the theme?</h4>
          <p>
            Our theme for this year&apos;s conference is &apos;Beyond
            Frontiers&apos;. It is intentionally open-ended: a frontier can be a
            physical boundary between places; a frontline of human knowledge; a
            division between individuals, ideologies, and political systems; and
            more.
          </p>
          <h4>How do I participate?</h4>
          <p>
            We are looking for 2 students who are impassioned about their topic.{" "}
            <b>
              <i>No prior speaking experience is required.</i>
            </b>
          </p>
          <h4>Your Video</h4>
          <p>
            Send in a short video (5 minutes or less) explaining why you would
            like to participate in TEDxOxford, what idea you would like to share
            and what inspires you to speak about it.
          </p>
          <br />
          <hr />
          <h4 className="deadline">
            <span style={{ fontWeight: 600 }}>DEADLINE:</span> 17th November
          </h4>
          <hr />
          {submitted === false && (
            <form className="student-apply" onSubmit={handleSubmit}>
              <p className="field-required">
                <span className="red-dot"></span>Field is required
              </p>

              <label>
                First Name:<span className="red-dot special"></span>
              </label>
              <input
                required
                type="text"
                value={formData.firstName}
                name="firstName"
                onChange={handleInputData("firstName")}
              />

              <label>
                Last Name:<span className="red-dot special"></span>
              </label>
              <input
                required
                type="text"
                value={formData.lastName}
                name="lastName"
                onChange={handleInputData("lastName")}
              />

              <label>
                University Email:<span className="red-dot special"></span>
              </label>
              <input
                required
                type="email"
                value={formData.email}
                name="email"
                onChange={handleInputData("email")}
              />

              <label>
                University:<span className="red-dot special"></span>
              </label>
              <select
                required
                value={formData.university}
                onChange={handleInputData("university")}
              >
                <option value="">Select University</option>
                <option value="University of Oxford">
                  University of Oxford
                </option>
                <option value="Oxford Brookes">
                  Oxford Brookes University
                </option>
              </select>

              <label>
                Course:<span className="red-dot special"></span>
              </label>
              <input
                required
                type="text"
                value={formData.course}
                name="course"
                onChange={handleInputData("course")}
              />

              <label>
                Year of Study:<span className="red-dot special"></span>
              </label>
              <input
                required
                type="number"
                value={formData.yearOfStudy}
                name="yearofstudy"
                onChange={handleInputData("yearOfStudy")}
              />

              <label>
                Talk Title:<span className="red-dot special"></span>
              </label>
              <input
                required
                type="text"
                value={formData.title}
                name="title"
                onChange={handleInputData("title")}
              />

              <label>
                Why would you like to apply?:
                <span className="red-dot special"></span>
              </label>
              <textarea
                required
                value={formData.why}
                onChange={handleInputData("why")}
              />

              <label>
                What idea would you like to share?:
                <span className="red-dot special"></span>
              </label>
              <textarea
                required
                value={formData.idea}
                onChange={handleInputData("idea")}
              />

              <label>
                Your Video:<span className="red-dot special"></span>
              </label>
              {isUploading && (
                <ProgressBar
                  className="file-progress"
                  animated
                  now={progress}
                  label={progress + "%"}
                />
              )}
              {formData.videoURL && (
                <img src={this.state.videoURL} alt={"file"} />
              )}
              <input
                type={"file"}
                name={"receiptUpload"}
                id="receiptUpload"
                bg={"white"}
                onChange={(e) => {
                  setFile(e.currentTarget.files[0]);
                }}
              />
              <input type="submit" value="Submit" />
            </form>
          )}
          <div className="student-apply">
            <div className="positive-message">
              {message && <p> {message}</p>}
            </div>
          </div>
        </div>
      </div>
      <LayeredWaves />
    </div>
  );
};
export default StudentSpeakerApply;
