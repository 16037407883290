import React from 'react';
import { BrowserRouter as Router,
  Routes,
  Route }                 from 'react-router-dom';
import Home               from './Home';
import Speakers           from './Speakers';
import Talks              from './Talks';
import Committee          from './Committee';
import About              from './About';
import Sponsors           from './Sponsors';
// import FAQ                from './FAQ'
// import RefundPolicy       from './RefundPolicy';
// import Schedule           from './Schedule';
import StudentSpeakerApply           from './StudentSpeakerApply';
import './App.scss';
import ReactGA from 'react-ga';




ReactGA.initialize('UA-201249248-1', {
  siteSpeedSampleRate: 100
});
ReactGA.pageview(window.location.pathname + window.location.search);



const App = () => {
  return(
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/speaker" element={<Speakers/>}/>
        <Route exact path="/talks" element={<Talks/>}/>
        <Route exact path="/committee" element={<Committee/>}/>
        <Route exact path="/about" element={<About/>}/>
        <Route exact path="/sponsors" element={<Sponsors/>}/>
        {/* <Route exact path="/faq" element={<FAQ/>}/> */}
        {/* <Route exact path="/refund-policy" element={<RefundPolicy/>}/> */}
        {/* <Route exact path="/schedule" element={<Schedule/>}/> */}
        <Route exact path="/apply/student-speaker" element={<StudentSpeakerApply/>}/>
      </Routes>
    </Router>
  )
}
export default App;